import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { pushCouponInfoToDataLayer } from 'services/coupons';
import AppliedCoupon from './AppliedCoupon';
import ClaimOfferCoupon from './ClaimOfferCoupon';
import styles from './CouponBanner.module.scss';

const CouponBanner = ({ coupon, setShowCouponModal, isApplied }) => {
  const couponCookie = Cookies.get('coupon_promotion_message');

  const couponMessage =
    (coupon && coupon.couponMessage) ||
    (couponCookie && decodeURI(couponCookie)) ||
    `$${coupon.discount} off your first delivery`;

  useEffect(() => {
    pushCouponInfoToDataLayer(coupon, couponMessage);
  }, [coupon, couponMessage]);

  return (
    <div className={styles.couponBannerContainer}>
      {isApplied ? (
        <AppliedCoupon setShowCouponModal={setShowCouponModal} couponMessage={couponMessage} />
      ) : (
        <ClaimOfferCoupon setShowCouponModal={setShowCouponModal} couponMessage={couponMessage} />
      )}
    </div>
  );
};

CouponBanner.propTypes = {
  coupon: PropTypes.shape().isRequired,
  setShowCouponModal: PropTypes.func.isRequired,
  isApplied: PropTypes.bool
};

CouponBanner.defaultProps = {
  isApplied: false
};

export default CouponBanner;
