import PropTypes from 'prop-types';

export const validOption = PropTypes.shape({
  price: PropTypes.shape({
    serving: PropTypes.string,
    shipping: PropTypes.string,
    total: PropTypes.string
  }),
  vegetarianOptionsAvailable: PropTypes.bool
});

export const planVariationProps = PropTypes.shape({
  options: PropTypes.objectOf(validOption),
  planType: PropTypes.string,
  displayName: PropTypes.string,
  product: PropTypes.shape({
    serves: PropTypes.number,
    name: PropTypes.string
  }),
  display: PropTypes.shape({
    description: PropTypes.shape({
      default: PropTypes.string
    }),
    displayNameColor: PropTypes.string,
    images: PropTypes.objectOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          url: PropTypes.string
        })
      )
    ),
    isVegetarian: PropTypes.bool
  }),
  id: PropTypes.string,
  defaultProductsPerOrder: PropTypes.number,
  frequency: PropTypes.string
});

export const variationType = PropTypes.shape({
  description: PropTypes.shape({
    default: PropTypes.string
  }),
  displayNameColor: PropTypes.string,
  displayNameVariation: PropTypes.string,
  images: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        url: PropTypes.string
      })
    )
  ),
  planApiName: PropTypes.string,
  isVegetarian: PropTypes.bool,
  preferences: PropTypes.arrayOf(PropTypes.string)
});

export const validFoodPlanVariation = PropTypes.shape({
  options: PropTypes.objectOf(validOption),
  planType: PropTypes.string,
  displayName: PropTypes.string,
  product: PropTypes.shape({
    serves: PropTypes.number,
    name: PropTypes.string
  }),
  display: variationType,
  preferences: PropTypes.arrayOf(variationType),
  id: PropTypes.string,
  defaultProductsPerOrder: PropTypes.number,
  frequency: PropTypes.string
});
