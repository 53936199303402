import store from 'redux/store';
import { useEffect } from 'react';
import Cookies from 'js-cookie';

import { showError, showSuccess, showWarning, showInfo } from 'redux/reducers/notices';
import { COMMON_ERROR_MESSAGE } from 'shared/constants/constants';

const { dispatch } = store;

export const METHOD = 'flash';
export const KEY = 'temporary_flash_message';
export const FLASH_COOKIE = 'flash_messages';

const useFlashMessageEffect = () => {
  useEffect(() => {
    try {
      const cookie = Cookies.getJSON(FLASH_COOKIE);

      if (!cookie) return;

      const notices = Object.keys(cookie);
      notices.forEach((notice) => {
        const message = decodeURIComponent(cookie[notice]).replace(/\+/g, ' ');

        switch (notice) {
          case 'notice':
            dispatch(showSuccess(KEY, message, METHOD));
            break;
          case 'info':
            dispatch(showInfo(KEY, message, METHOD));
            break;
          case 'success':
            dispatch(showSuccess(KEY, message, METHOD));
            break;
          case 'warning':
            dispatch(showWarning(KEY, message, METHOD));
            break;
          case 'alert':
            dispatch(showError(KEY, message, METHOD));
            break;
          default:
            break;
        }
      });

      Cookies.remove(FLASH_COOKIE);
    } catch (error) {
      dispatch(showError(KEY, COMMON_ERROR_MESSAGE, METHOD));
    }
  }, []);
};

export default useFlashMessageEffect;
