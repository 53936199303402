import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withPreventDefault from 'shared/utils/with-prevent-default';

const QuantitySelector = ({ quantities, quantitiesMapper, onSelect, selected, type, fullWidth, id, uit }) => {
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const findAndSelectIndex = (newSelectedQuantity) => {
    const currentIndex = quantities.findIndex((quantity) => quantity === newSelectedQuantity);
    if (currentIndex < 0) {
      setSelectedIndex(0);
    } else if (currentIndex !== selectedIndex) {
      setSelectedIndex(currentIndex);
    }
  };

  useEffect(() => {
    setSelectedQuantity(selected || quantities[0]);
    const newSelectedQuantity = selected || quantities[0];
    findAndSelectIndex(newSelectedQuantity);
  }, [selected, quantities]);

  const handleSelect = (quantity, index) => {
    setSelectedQuantity(quantity);
    setSelectedIndex(index);
    onSelect(quantity);
  };

  const buildLabel = (quantity, index) => {
    const isSelected = quantity === selectedQuantity;

    return (
      <li key={`${quantity}`} className={isSelected ? 'selected' : ''} data-testid={isSelected ? 'selected' : ''}>
        <button
          type="button"
          onClick={withPreventDefault(() => handleSelect(quantity, index))}
          id={quantitiesMapper && quantitiesMapper[quantity] ? `${quantitiesMapper[quantity]}Toggle` : null}
        >
          {quantity}
        </button>
      </li>
    );
  };

  return (
    <ul
      uit={uit}
      id={id}
      style={{ width: fullWidth ? '100%' : 'auto' }}
      className={`pom-QuantitySelector pom-QuantitySelector__${type}`}
      data-selected={selectedIndex}
      data-length={quantities.length}
      data-type={type}
    >
      {quantities.map((quantity, index) => buildLabel(quantity, index))}
    </ul>
  );
};

const { bool, number, arrayOf, func, oneOf, oneOfType, shape, string } = PropTypes;

QuantitySelector.propTypes = {
  quantities: arrayOf(oneOfType([number, string])).isRequired,
  quantitiesMapper: shape(),
  onSelect: func.isRequired,
  selected: oneOfType([number, string]),
  type: oneOf(['rectangle', 'rounded', 'regular', 'circle', 'block', 'block-rounded', 'rounded-large-text']),
  fullWidth: bool,
  id: string,
  uit: string
};

QuantitySelector.defaultProps = {
  quantitiesMapper: null,
  selected: undefined,
  type: 'regular',
  fullWidth: false,
  id: undefined,
  uit: undefined
};

export default QuantitySelector;
