import PropTypes from 'prop-types';

export const stylesProps = PropTypes.object;

export const classNamesProps = PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.bool)]);

export default {
  classNamesProps,
  stylesProps
};
