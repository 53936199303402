import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { stylesProps } from 'shared/prop-types/styling';

import Currency from '../Currency/Currency';
import Spinner from '../Spinner/Spinner';

const Pricing = (props) => {
  if (props.isLoading) {
    return (
      <div className="pom-Pricing--loader">
        <Spinner size={4} />
      </div>
    );
  }

  const price =
    parseFloat(props.price) === 0 && props.transform ? (
      <span
        className={cx(props.isUpdatedPlanSelector ? 'pom-Pricing--cyan' : 'pom-Pricing--green', {
          'pom-Pricing--free': props.transformText === 'FREE'
        })}
        uit={props.uit}
        id={props.id}
        style={props.textStyles}
      >
        {props.transformText}
      </span>
    ) : (
      <span>
        <Currency prefix={props.isDiscount ? '-$' : undefined} {...props} />
      </span>
    );
  return price;
};

Pricing.propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  transform: PropTypes.bool,
  transformText: PropTypes.string,
  isLoading: PropTypes.bool,
  isDiscount: PropTypes.bool,
  uit: PropTypes.string,
  id: PropTypes.string,
  textStyles: stylesProps,
  isUpdatedPlanSelector: PropTypes.bool
};

Pricing.defaultProps = {
  uit: undefined,
  id: undefined,
  transform: true,
  transformText: 'FREE',
  isDiscount: false,
  isLoading: false,
  textStyles: { textTransform: 'uppercase' },
  isUpdatedPlanSelector: false
};

export default Pricing;
