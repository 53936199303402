import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/Button';
import { useRouter } from 'next/router';
import cx from 'classnames';

import { TRACK_EVENTS } from 'shared/utils/segment/constants';
import { performTrack } from 'shared/utils/segment/utils';
import styles from './ClaimOfferCoupon.module.scss';

const ClaimOfferCoupon = ({ couponMessage, setShowCouponModal }) => {
  const router = useRouter();

  const handleLearnMoreClick = () => {
    setShowCouponModal(true);
  };

  const handleRedeemOffer = () => {
    const currentLocation = window.location.pathname;
    const redirectLocation = '/pricing';
    performTrack({
      event: TRACK_EVENTS.elementClicked,
      properties: {
        type: 'button',
        text: 'Claim Offer',
        destination: redirectLocation,
        location: currentLocation,
        id: 'coupon-claim-offer'
      }
    });
    router.push(redirectLocation);
  };

  return (
    <div className={styles.newCouponContainer}>
      <div className={styles.couponBox}>
        {couponMessage && (
          <div onClick={handleLearnMoreClick} className={cx(styles.promotionText, styles.message)}>
            <p>{couponMessage}</p>
          </div>
        )}
        <Button
          color="white"
          size="small"
          buttonType="pill-filled"
          text="Claim Offer"
          classNames={styles.btnOffer}
          onClick={handleRedeemOffer}
        />
      </div>
    </div>
  );
};

ClaimOfferCoupon.propTypes = {
  couponMessage: PropTypes.string.isRequired,
  setShowCouponModal: PropTypes.func.isRequired
};

export default ClaimOfferCoupon;
