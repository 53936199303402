import React from 'react';
import PropTypes from 'prop-types';
import Checkmark from 'components/common/icons/Checkmark/Checkmark';
import styles from './AppliedCoupon.module.scss';

const AppliedCouponContainer = ({ couponMessage, setShowCouponModal }) => {
  const handleLearnMoreClick = () => {
    setShowCouponModal(true);
  };

  return (
    <div className={styles.newCouponContainer}>
      <div className={styles.couponBox}>
        <Checkmark
          classNames={styles.circleCheck}
          height="28px"
          width="28px"
          stroke="#fff"
          bold={true}
          strokeWidth="3"
          fill="#002684"
          outlineStrokeWidth="0"
        />
        <div className={styles.message} onClick={handleLearnMoreClick}>
          {couponMessage && (
            <p className={styles.promotionText}>
              <span className={styles.appliedText}>APPLIED:&nbsp;</span>
              {couponMessage}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

AppliedCouponContainer.propTypes = {
  couponMessage: PropTypes.string.isRequired,
  setShowCouponModal: PropTypes.func.isRequired
};

export default AppliedCouponContainer;
