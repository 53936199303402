// @flow

import { apiPost } from 'services/fetch';

const apiVersion = 'v20161006';

export const newsletterSubscribe = (data) => {
  return apiPost('newsletter/subscribe', data, apiVersion).then((response) => response);
};

export default { newsletterSubscribe };
