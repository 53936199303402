import * as Cookie from 'js-cookie';

const STORAGE_KEY = 'plan_selection';

export const getPlanSelectionFromCookie = () => {
  const cookie = Cookie.get(STORAGE_KEY);
  if (!cookie) return undefined;

  const { planApiName, id, quantity, preferences } = JSON.parse(cookie);
  return {
    planApiName,
    plan_id: id,
    products_per_delivery: quantity,
    preferences
  };
};

export const removePlanSelectionFromCookie = () => {
  Cookie.remove(STORAGE_KEY, { path: '/' });
};

export const setPlanSelectionCookie = (id, quantity, planApiName, preferences) => {
  Cookie.set(STORAGE_KEY, `${JSON.stringify({ id, quantity, planApiName, preferences })}`);
};
