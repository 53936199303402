import React from 'react';
import Head from 'next/head';
import { HomepageApp } from 'components/homepage/HomeApp/HomeApp';
import metadata from 'shared/constants/meta-tags';
import { Provider } from 'react-redux';
import store from 'redux/store';

const HomePage = () => (
  <>
    <Head>
      <title>{metadata.home.title}</title>
      <meta property="og:title" content={metadata.home.title} key="title" />
      <meta name="description" content={metadata.home.description} key="description" />
    </Head>
    <Provider store={store}>
      <HomepageApp />
    </Provider>
  </>
);

export default HomePage;
