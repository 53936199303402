import { useEffect } from 'react';

const useActivateOptimizelyPageEffect = (route) =>
  useEffect(() => {
    if (window.optimizely) {
      // Precautionarily deactivate a page before activation
      window.optimizely.push({
        type: 'page',
        pageName: route,
        isActive: false
      });

      window.optimizely.push({
        type: 'page',
        pageName: route
      });
    }
  }, [route]);

export default useActivateOptimizelyPageEffect;
