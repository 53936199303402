import React from 'react';
import cx from 'classnames';
import { whyBaTiles } from '../constants';
import styles from './HomepageSection.module.scss';

const HomepageSection = () => {
  return (
    <section className={styles.homePageSection}>
      <span className={styles.sectionTitle}> 530+ million meals shipped</span>
      <p className={styles.sectionDescription}> See why home cooks stick with the original American meal kit.</p>
      <div className={styles.whyBaSection}>
        {whyBaTiles.map((whyBaTile) => (
          <div key={whyBaTile.photoTitle} className={styles.baPhoto}>
            <img src={whyBaTile.image} alt={whyBaTile.alt} height="374" width="561" />
            <p className={styles.baPhotoTitle}>{whyBaTile.photoTitle}</p>
            <p className={styles.baPhotoSubtitle}>{whyBaTile.subtitle1}</p>
            <p className={styles.baPhotoSubtitle}>{whyBaTile.subtitle2}</p>
          </div>
        ))}
      </div>
      <p className={styles.getStarted}>
        Get started for as little as <span className={styles.textStyle1}>$7.99 per serving</span>
      </p>
      <a className={cx('btn', styles.getCookingBtn)} id="splash-get-cooking-btn" href="/pricing">
        See plans
      </a>
    </section>
  );
};

export default HomepageSection;
