import React from 'react';
import styles from './GetStarted.module.scss';

const GetStarted = () => {
  return (
    <section className={styles.gettingStartedSection}>
      <img
        src="https://media.blueapron.com/assets/registration/homepage/gnocchi-ingredients.webp?height=400&amp;quality=90"
        alt="ba chef"
        loading="lazy"
        width="1208"
        height="343"
      />
      <div className={styles.fixed}>
        <p className={styles.title}>Get started now</p>
        <p className={styles.subTitle}>
          for as little as <span>$7.99 per serving</span>
        </p>
        <a className={styles.splashGetStartedBtn} id="splash-get-started-btn" href="/pricing">
          See plans
        </a>
      </div>
    </section>
  );
};

export default GetStarted;
