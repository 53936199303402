import React from 'react';

import { TRACK_EVENTS } from 'shared/utils/segment/constants';
import { performTrack } from 'shared/utils/segment/utils';
import Banner from './Banner';

const BannerContainer = () => {
  const handleSeePlansClick = (id) => {
    performTrack({
      event: TRACK_EVENTS.elementClicked,
      properties: {
        type: 'link',
        text: 'See Plans',
        destination: '/pricing',
        id
      }
    });
  };

  return <Banner onSeePlansClick={handleSeePlansClick} />;
};

export default BannerContainer;
