const withPreventDefault =
  (method) =>
  (...args) => {
    const [e] = args;
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    method(...args);
  };

export default withPreventDefault;
