/* istanbul ignore file */
import Metrics from 'services/metrics/track-event';

export const trackPageView = () => {
  Metrics.trackEvent({
    action: 'Page View',
    category: 'Homepage',
    exclusionList: []
  });
};

export const browseMenuClick = () => {
  Metrics.trackEvent({
    action: 'Browse Our Menus Button Click',
    category: 'Homepage'
  });
};

export const weeklyOptionsTileClick = (title) => {
  Metrics.trackEvent({
    category: 'Homepage',
    action: 'Tile Clicked',
    attributes: { tileTitle: title }
  });
};

export const appStoreClick = () => {
  Metrics.trackEvent({
    action: 'AppStore See Plans Button Click',
    category: 'Homepage'
  });
};

export const preparedAndReadyHeroClick = () => {
  Metrics.trackEvent({
    action: 'Prepared & Ready Hero Click',
    category: 'Homepage'
  });
};

export const mealKitHeroClick = () => {
  Metrics.trackEvent({
    action: 'Meal Kit Hero Click',
    category: 'Homepage'
  });
};

export const trackNewsletterSignupEngage = () => {
  Metrics.trackEvent({
    action: 'Newsletter Signup Engaged',
    category: 'Footer'
  });
};

export const trackNewsletterSignupSubmit = () => {
  Metrics.trackEvent({
    action: 'Newsletter Signup Submit',
    category: 'Footer'
  });
};
