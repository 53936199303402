import React, { useState, useEffect } from 'react';
import { reviews } from '../constants';
import styles from './TestimonialSection.module.scss';

const TestimonialSection = () => {
  const [reviewIndex, setReviewIndex] = useState(0);
  const reviewCount = reviews.length;

  useEffect(() => {
    let index = 0;
    const interval = setInterval(function () {
      const newIndex = (index + 1) % reviewCount;
      index = newIndex;
      setReviewIndex(newIndex);
    }, 5000);

    return () => clearInterval(interval);
  }, [reviewCount]);

  return (
    <section className={styles.testimonialSection}>
      <img
        src="https://media.blueapron.com/assets/registration/homepage/cooking-pot.webp?height=600&amp;quality=90"
        alt="ba chef"
        loading="lazy"
        height="600"
        width="1342"
      />
      <div className={styles.fixed}>
        <img
          src="https://media.blueapron.com/assets/registration/homepage/texture-pattern.webp?height=20&amp;quality=90"
          alt="texture pattern"
          loading="lazy"
        />
        <p className={styles.title}>Celebrating 10 years of happy customers</p>
        <p className={styles.testimonialText} id="testimonial-text">
          {reviews[reviewIndex][0]}
        </p>
        <div className={styles.rectangle} />
        <p className={styles.userName} id="testimonial-user-name">
          {reviews[reviewIndex][1]}
        </p>
      </div>
    </section>
  );
};

export default TestimonialSection;
