import * as Cookie from 'js-cookie';

const STORAGE_KEY = 'user_attributes';

const getUserFromCookie = () => {
  const cookie = Cookie.get(STORAGE_KEY);
  if (!cookie) return undefined;

  return JSON.parse(cookie);
};

export default getUserFromCookie;
