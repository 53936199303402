import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import cx from 'classnames';
import { TRACK_EVENTS } from 'shared/utils/segment/constants';
import { performTrack } from 'shared/utils/segment/utils';
import desktopSplashImage from 'public/images/desktop-splash-v3.png';
import mobileSplashImage from 'public/images/mobile-splash-v3.png';

import styles from './HomepageHero.module.scss';

const HomepageHero = () => {
  const seePlansDestination = '/pricing';

  const handleSeePlansClick = () => {
    performTrack({
      event: TRACK_EVENTS.elementClicked,
      properties: {
        type: 'link',
        text: 'See Plans',
        destination: seePlansDestination,
        id: 'homepage-hero-see-plans'
      }
    });
  };

  return (
    <section className={cx(styles.ctaSplash)}>
      <div className={cx(styles.backgroundImageOverlay)}>
        <div className={styles.homepageHero}>
          <Image
            src={desktopSplashImage}
            alt="A spread of Blue Apron meals and ingredients"
            fill
            placeholder="blur"
            priority
            sizes="100vw"
            style={{
              objectFit: 'cover',
              maxWidth: '100%'
            }}
          />
        </div>
        <div className={styles.homepageHeroMobile}>
          <Image
            src={mobileSplashImage}
            fill
            alt="A spread of Blue Apron meals and ingredients"
            priority
            sizes="100vw"
            style={{
              objectFit: 'cover',
              maxWidth: '100%'
            }}
          />
        </div>
        <div className={styles.topContainer}>
          <div className={styles.topContainerTxt}>
            <h2 className="hero-title js-hero-image-title">
              Easy meal kits.
              <br />
              Quality ingredients.
              <br />
              Delivered to your door.
            </h2>
            <Link href={seePlansDestination}>
              <span
                onClick={handleSeePlansClick}
                className={cx('btn', styles.getCookingBtn)}
                id="splash-get-cooking-btn"
                onKeyDown={handleSeePlansClick}
                tabIndex={0}
                role="link"
              >
                See plans
              </span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageHero;
