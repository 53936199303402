import Metrics from 'services/metrics/track-event';

const CHAT_CATEGORY = 'Chat';
const CHAT_ACTIONS = {
  CHAT_BUTTON_SHOWN: 'Chat Button Shown'
};

export const hide = () => {
  if (window.zE) {
    window.zE(() => {
      window.zE('messenger', 'hide');
    });
  }
};

export const show = () => {
  if (window.zE) {
    window.zE(() => {
      window.zE('messenger', 'show');
    });

    Metrics.trackEvent({
      action: CHAT_ACTIONS.CHAT_BUTTON_SHOWN,
      category: CHAT_CATEGORY
    });
  }
};

export default Object.freeze({
  show,
  hide
});
