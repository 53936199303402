import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import Button from 'components/common/Button';
import Metrics from 'services/metrics/track-event';
import { TRACK_EVENTS } from 'shared/utils/segment/constants';
import { performTrack } from 'shared/utils/segment/utils';
import { pushCouponInfoToDataLayer } from 'services/coupons';
import styles from './HomepageCouponBanner.module.scss';

const HomepageCouponBanner = ({ coupon, setShowCouponModal }) => {
  const couponCookie = Cookies.get('coupon_promotion_message');
  const router = useRouter();

  const couponMessage =
    (coupon && coupon.couponMessage) ||
    (couponCookie && decodeURI(couponCookie)) ||
    `$${coupon.discount} off your first delivery`;

  const handleRedeemOffer = () => {
    const currentLocation = window.location.pathname;
    const redirectLocation = '/pricing';
    performTrack({
      event: TRACK_EVENTS.elementClicked,
      properties: {
        type: 'button',
        text: 'Redeem Offer',
        destination: redirectLocation,
        location: currentLocation,
        id: 'homepage-header-redeem-offer'
      }
    });
    Metrics.trackEvent({
      category: 'Home Page Banner',
      action: 'Redeem Offer',
      attributes: {
        coupon_code: coupon?.code
      }
    });
    router.push(redirectLocation);
  };

  useEffect(() => {
    pushCouponInfoToDataLayer(coupon, couponMessage);
  }, [coupon, couponMessage]);

  const handleLearnMoreClick = () => {
    Metrics.trackEvent({
      category: 'Home Page Banner',
      action: 'Terms Linked Text Click',
      attributes: {
        coupon_code: coupon?.code
      }
    });
    setShowCouponModal(true);
  };

  return (
    <div className={styles.homepageCouponBannerContainer}>
      <div className="promotion-container-original">
        <div className={styles.promoContent}>
          <span className={styles.promoMessage}>
            {couponMessage.length > 100 ? `${couponMessage.substring(0, 100).trim()}...` : couponMessage}{' '}
            <span onClick={handleLearnMoreClick} className={styles.terms}>
              Terms
            </span>
          </span>
          <Button
            buttonType="pill-filled"
            color="orange"
            size="small"
            text="redeem offer"
            onClick={handleRedeemOffer}
            classNames={styles.redeemButton}
            styles={{ height: '35px', fontSize: '12px', fontWeight: 500, margin: 0 }}
          />
        </div>
      </div>
    </div>
  );
};

HomepageCouponBanner.propTypes = {
  setShowCouponModal: PropTypes.func.isRequired,
  coupon: PropTypes.shape().isRequired
};

export default HomepageCouponBanner;
