import React from 'react';
import cx from 'classnames';
import styles from './ShortRatingsBanner.module.scss';

const RatingsBanner = () => (
  <div id="app-store-section">
    <div className={styles.ratingsSection}>
      <span className={styles.ratingsTitle}>
        Our customers think we&apos;re&nbsp;
        <span className={styles.appStoreText}>EXCELLENT</span>
      </span>
      <div className={styles.ratingContainer}>
        <div className={styles.ratingLogo}>
          <img
            src="https://media.blueapron.com/assets/registration/homepage/appstore_ratings_icon.svg"
            alt="app store rating"
            loading="lazy"
            height={24}
            className={styles.ratingsImg}
          />
          <span className={styles.ratingText}>4.7</span>
        </div>
        <p className={cx(styles.ratingsSubText, styles.appStoreSubtext)}>
          <span className={styles.hideOnMobile}>Based on&nbsp;</span>
          <strong>40K</strong>
          &nbsp;App Store Ratings
        </p>
      </div>
    </div>
  </div>
);

export default RatingsBanner;
