import React from 'react';
import PropTypes from 'prop-types';

const Currency = (props) => {
  // eslint-disable-next-line no-restricted-globals
  const price = isNaN(props.price) ? '--' : Number(props.price).toFixed(props.decimalPlaces);

  return (
    <span uit={props.uit} id={props.id}>
      {props.prefix}
      {price}
    </span>
  );
};

Currency.propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  prefix: PropTypes.oneOf(['$', '-$']),
  decimalPlaces: PropTypes.number,
  uit: PropTypes.string,
  id: PropTypes.string
};

Currency.defaultProps = {
  prefix: '$',
  decimalPlaces: 2,
  uit: undefined,
  id: undefined
};

export default Currency;
