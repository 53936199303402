export const reviews = [
  [
    'Before Blue Apron, I tried HelloFresh. Blue Apron beats them on all counts—directions, freshness, packing, and prep.',
    '— Cheryl'
  ],
  [
    "We love Blue Apron! It eliminates a ton of food waste while allowing us to try new things and have tasty meals. Really great quality food, awesome flavors, and things we wouldn't otherwise have any idea how to create.",
    '— Christy'
  ],
  ['We changed from HelloFresh to Blue Apron and it was the best choice we made!', '— Leslie'],
  [
    "We'd tried many other food delivery services and none of them have compared in quality and variety to Blue Apron.",
    '— Katie'
  ],
  [
    'My entire family loves the dishes I make! This truly makes my life easier as a working mom! It allows my family to sit down together and enjoy our dinner. I love that my kids love it.',
    '— Angie'
  ]
];

export const mealTiles = [
  {
    image:
      'https://media.blueapron.com/assets/registration/homepage/craft.webp?width=300&amp;height=300&amp;quality=90',
    title: 'Craft',
    subtitle: 'Cheese Crisp Burgers',
    alt: 'Burger with cheese crisps and fries'
  },
  {
    image:
      'https://media.blueapron.com/assets/registration/homepage/wellness.webp?width=300&amp;height=300&amp;quality=90',
    title: 'Wellness',
    subtitle: 'Turkey & Mushroom Lettuce Cups',
    alt: 'Bowls with lettuce, cucumber, and turkey and pepper mix'
  },
  {
    image:
      'https://media.blueapron.com/assets/registration/homepage/family-friendly.webp?width=300&amp;height=300&amp;quality=90',
    title: 'Family Friendly',
    subtitle: 'Mafalda Pasta',
    alt: 'Pasta covered with tomato based sauce mixed with capers and zucchini slices'
  },
  {
    image:
      'https://media.blueapron.com/assets/registration/homepage/fast-and-easy.webp?width=300&amp;height=300&amp;quality=90',
    title: 'Fast & Easy',
    subtitle: 'Sheet Pan Cheesy Jalapeño Chicken',
    alt: 'A tray with cheese covered chicken breasts and string beans covered in pepper flakes'
  },
  {
    image:
      'https://media.blueapron.com/assets/registration/homepage/vegetarian.webp?width=300&amp;height=300&amp;quality=90',
    title: 'Vegetarian',
    subtitle: 'Quinoa & Vegetable “Fried Rice”',
    alt: 'Bowl with broccoli and carrot medley topped with fried egg and peanuts'
  },
  {
    image:
      'https://media.blueapron.com/assets/registration/homepage/premium-steak.webp?width=300&amp;height=300&amp;quality=90',
    title: 'Premium',
    subtitle: 'NY Strip Steaks & Herb-Mushroom Pan Sauce',
    alt: 'Plate with steak drizzled with mushrooms and sauce next to mashed potatoes and cooked greens'
  },
  {
    image:
      'https://media.blueapron.com/assets/registration/homepage/ready-to-cook.webp?width=300&amp;height=300&amp;quality=90',
    title: 'Ready to Cook',
    subtitle: 'Pesto Chicken and Orzo',
    alt: 'Cooked meal in a ready made pan'
  },
  {
    image:
      'https://media.blueapron.com/assets/registration/homepage/prepped-and-ready-tile.webp?width=300&amp;height=300&amp;quality=90',
    title: 'Prepared & Ready',
    subtitle: 'Chicken Parmesan',
    alt: 'Prepared and ready tray with a picture of chicken parmesan on the cover'
  },
  {
    image:
      'https://media.blueapron.com/assets/registration/homepage/breakfast.webp?width=300&amp;height=300&amp;quality=90',
    title: 'Breakfast',
    subtitle: 'Fried Egg & Prosciutto Sandwiches',
    alt: 'Plate with Egg & Prosciutto peaking out of a potato bun'
  },
  {
    image:
      'https://media.blueapron.com/assets/registration/homepage/desserts.webp?width=300&amp;height=300&amp;quality=90',
    title: 'Desserts',
    subtitle: 'Flourless Chocolate Cake',
    alt: 'Chocolate cake with corner slice moved to a plate and topped with whipped cream'
  }
];

export const whyBaTiles = [
  {
    image: 'https://media.blueapron.com/assets/registration/homepage/chef-experience.webp?height=374&quality=90',
    alt: 'ba chef',
    photoTitle: '5 decades of top restaurant experience',
    subtitle1: 'Our chefs bring high standards to',
    subtitle2: 'crafting your meals.'
  },
  {
    image: 'https://media.blueapron.com/pricing/howitworks/unpack_your_box.jpg?height=374&quality=90',
    alt: 'ba-ingredients',
    photoTitle: 'Fresher ingredients faster',
    subtitle1: '80% of ingredients come directly from',
    subtitle2: 'producers.'
  },
  {
    image: 'https://media.blueapron.com/pricing/howitworks/choose_your_meals.jpg?height=374&quality=90',
    alt: 'ba flexible',
    photoTitle: "You're busy, so we're flexible",
    subtitle1: 'Get boxes on your schedule. Skip,',
    subtitle2: 'pause, or cancel anytime.'
  }
];
